.footer {
    font-size: 10pt;
    margin-top: 400px;
    position:absolute;
    left:0;
    bottom:0;
    right:0;
}

.footer-column {
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.address-pre {
    margin-top: 50px;
    font-size: 16px;
}

@media (max-width: 1080px) {
    .footer {
        font-size: 8pt;
        position:absolute;
        left:0;
        bottom:0;
        right:0;
    }

    .footer-column {
        width: 70%;
    }
    
}


@media (max-hight: 1080px) {
    .footer {
        font-size: 8pt;
        position:absolute;
        left:0;
        bottom:0;
        right:0;
    }

    .footer-column {
        width: 70%;
    }
}
