.MainPage {
  margin-top: 20px; /* navbar-height + 5px */
  margin-right: 5px;
  font-size: 20px;
  width: 100%;
  padding-right: 10px;
}

.header-row {
  height: 100px;
}

.logo-col {
  margin-left: -10px;
}

.bierglas-main {
  width: 100%;
}

.content-row {
  margin-left: 250px;
  margin-right: 100px;
}

.zip-row {
  margin-left: 250px;
  margin-right: 100px;
}

.construction-side {
  width: 100%;
}

.table-scrollbar {
  position: relative;
  height: 500px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}

@media (max-width: 1080px) {
  .MainPage {
    margin-top: 150px;
    margin-right: 5px;
    font-size: 10px;
    width: 100%;
    padding-right: 2px;
  }

  .header-row {
    width: 100%;
    height: 280px;
  }

  .logo-col {
    margin-left: -10px;
  }
  
  .bierglas-main {
    width: 25%;
  }

  .content-row {
    margin-left: 50px;
    width: 100%;
    padding-bottom: 100px;
  }

  .zip-row {
    margin-left: 0px;
    width: 100%;
    margin-right: 100px;
  }

  .table-scrollbar {
    position: relative;
    height: 500px;
    overflow: auto;
  }

  .table-wrapper-scroll-y {
    display: block;
  }

  .construction-side {
    width: 30%;
  }
}
